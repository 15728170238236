.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #57b8c1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Signin-body {
  background-image: "..";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.body-img {
  background-image: url("../src/resources/images/aaaa.jpg")
}

.body-img1 {
  background-image: url("../src/resources/images/aaa.jpg")
}

.body-img2 {
  background-image: url("../src/resources/images/bbbb.jpg")
}

.background1 {
  background-color: #61dafb;
  background:rgba(8, 8, 8, 0.863);
  backdrop-filter: blur(10px);
}
.nav-link {
  color: rgb(0, 7, 0);
}

.nav-item>a:hover {
  color: green;
}

/*code to change background color*/
.navbar-nav>.active>a {
  background-color: #0bc3f1;
  color: rgb(0, 8, 0);
}